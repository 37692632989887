import React from 'react';

const Segmentation = () => (
    <div>
        <h1>Calibration Component</h1>
        <p>This is an empty calibration component.</p>
    </div>
);

export default Segmentation;
